/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box, ChakraProvider } from '@chakra-ui/core'
import { Flex } from "@chakra-ui/core"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ChakraProvider>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      

      <Flex
        as="flex"
        overflowX="hidden"
        direction="column"
        margin="10 auto"
        width="100%"
        padding="10px"
      >

        <main>{children}</main>

        <Footer />
      </Flex>
      
    </ChakraProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
