import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Flex, Heading, Box, IconButton, Text
} from "@chakra-ui/core";

import { Link as ReachLink } from "@reach/router"
import { HiMenu } from 'react-icons/hi'

const MenuItems = ({ children, hashLink }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    <Link as={ReachLink}
      to={`/${hashLink}`}
      color="black">
      {children}
    </Link>
  </Text>

);

const Header = ({ siteTitle }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (


    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
    >
      <Flex
        align="center"
        mr={5}>

        <Heading as="h1" size="xl" letterSpacing={"-.1rem"}>
          <Link
            to="/"
            style={{
              color: `black`,
              fontWeight: `bold`,
              textDecoration: `none`,
              margin: `0 auto`
            }}
          >
            {siteTitle}
          </Link>
        </Heading>
      </Flex>



      <Box display={{ base: "block", md: "none" }} >
        <IconButton
          colorScheme="teal"
          aria-label="Menu icon"
          icon={<HiMenu />}
          onClick={() => handleToggle()}
        />
      </Box>

      <Box
        display={{ base: show ? "block" : "none", sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="end"

      >
        <MenuItems hashLink="#main-section" >Home</MenuItems>
        <MenuItems hashLink="#demo-video">Demo</MenuItems>
        <MenuItems hashLink="#use-cases-section">Use Cases</MenuItems>
        <MenuItems hashLink="#end-subsribe-form-section">Subscribe</MenuItems>
        <MenuItems hashLink="#follow-us-section">Follow us</MenuItems>
      </Box>

    </Flex>

  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
