import React from 'react'
import { IconButton, Flex } from "@chakra-ui/core";
import { ArrowUpIcon } from '@chakra-ui/icons'
import { navigate } from 'gatsby';

const Footer = () => {
  return (
    <footer style={{
      marginTop: `2rem`,
    }}>
      <Flex
        direction="row"
        justify="space-between"
        align="center"

        maxWidth="1200px"
      >
        <div>
          © {new Date().getFullYear()}, Made by
                {` `}
          <a href="https://twitter.com/upupkenchoong">@upupkenchoong</a>

        </div>

        <IconButton
          size="lg"
          icon={<ArrowUpIcon />}
          onClick={() => navigate("/")}
        />

      </Flex>

    </footer>
  );
}

export default Footer